const timeOfOrdering = (date) => {
  if (!date) return;

  const [day, time] = date.split(' ');
  const [yyyy, mm, dd] = day.split('-');
  const [h, m, s] = time.split(':');
  return `${h}:${m} ${dd}.${mm}`;
};

const formatDateForBE = (date) => {
  if (!date) return;

  let [day, mounth, year] = date.split('.');
  return `${year}-${mounth}-${day}`;
};
export { timeOfOrdering, formatDateForBE };
