import { render, staticRenderFns } from "./App.vue?vue&type=template&id=5a5b8f72&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../home/gitlab-runner/.nvm/versions/node/v12.14.1/lib/node_modules/@briskly/vue-cli/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports