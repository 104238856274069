export default [
  {
    path: 'videos',
    name: 'videos',
    component: () => import('@/apps/videos/views/Entry/Entry'),
  },

  {
    path: 'videos/:id',
    name: 'videos$videos',
    component: () => import('@/apps/videos/views/Videos/Videos'),
  },
];
