<template>
  <router-view />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

const isCookieSet = () => {
  try {
    return document.cookie.split(';').map(i => i.trim()).includes('redirected=1');
  } catch (e) {
    console.error(e);
    return false;
  }
};

export default {
  methods: {
    ...mapActions({
      useDimensions: 'core$clientmeta/useDimensions',
    }),
  },

  created() {

    const isRedirected = location.search.indexOf('?redirected') !== -1 || isCookieSet();
    if (isRedirected) {
      if (!isCookieSet()) {
        document.cookie = `redirected=1; max-age=${60*60*24*7}`;
      }
    } else {
      location.href = 'https://briskly.business/?redirected';
    }

    this.useDimensions();
    this.$nextTick(() => {
      window.addEventListener('resize', this.useDimensions);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.useDimensions);
  },
};
</script>
