import { companyAPI } from '@/core/api/company';
import { formatDateForBE } from '@/apps/videos/scripts/dateparser';

const state = {
  loading: false,

  orders: {
    items: [],
    meta: {},
    order: {},
  },

  videos: {
    items: [],
    meta: {},
  },

  currentStore: {},
  currentOrder: {},
  selectedDate: '',
  videoLink: '',
};
const getters = {
  orders: (state) => state.orders.items,
  videos: (state) => state.videos.items,
  currentStore: (state) => state.currentStore,
  selectedDate: (state) => formatDateForBE(state.selectedDate),
  selectedDateRaw: (state) => state.selectedDate,
  currentOrder: (state) => state.currentOrder,
  videoLink: (state) => state.videoLink,
};

const actions = {
  getOrdersByStore: async ({ commit, getters, state }) => {
    if (state.loading) return;
    state.loading = true;

    const payload = {
      id: getters.currentStore.id,
      page: state.orders.meta.page ? state.orders.meta.page + 1 : 1,
      limit: 20,
      date: getters.selectedDate,
      ...(state.orders.order && { order: state.orders.order }),
    };

    if (payload.page - 1 === state.orders.meta.total_pages) return;
    try {
      const orders = await companyAPI.getOrdersByStore(payload);
      commit('_setOrders', { ...orders });
    } catch (error) {
      console.log(error);
    } finally {
      state.loading = false;
    }
  },

  setOrdersOrder: async ({ commit, dispatch }, { order }) => {
    await commit('_cleanUpOrders');
    await commit('_setOrdersOrder', order);
    await dispatch('getOrdersByStore');
  },

  setCurrentStore: ({ commit }, opts) => {
    commit('_setCurrentStore', opts);
  },

  setSelectedDateRaw: ({ commit }, date) => {
    commit('_setSelectedDateRaw', date);

    commit('_cleanUpOrders');
  },

  fetchVideos: async ({ commit, state, getters }) => {
    state.loading = true;

    const payload = {
      ...state.videos.meta,
      id: state.currentOrder.id,
      date: getters.selectedDate,
    };

    const videos = await companyAPI.getVideoByOrder(payload);
    commit('_setVideos', videos);

    state.loading = false;
  },

  getVideoLink: async ({ commit }, { id }) => {
    try {
      const link = await companyAPI.generateVideoLink({ video_id: id });
      commit('_setVideoLink', link);
    } catch (error) {
      console.log(error);
    }
  },

  setCurrentOrder: ({ commit }, order) => {
    commit('_setCurrentOrder', order);
    if (getters.videos.length !== 0) {
      commit('_cleanUpVideos');
    }
  },

  cleanUp: ({ commit }) => {
    commit('_cleanUpOrders');
    commit('_cleanUpVideos');
    commit('_cleanUpCurrentOrder');
  },

  postClaim: async ({ commit }, { id, text }) => {
    try {
      await companyAPI.postClaimAboutOrder({ id, text });
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  _setOrders: (state, { items, meta, action }) => {
    state.orders.items = [...state.orders.items, ...items];
    state.orders.meta = meta;
  },

  _setCurrentStore: (state, { id, name }) => {
    state.currentStore = { ...state.currentStore, id, name };
  },

  _setSelectedDateRaw: (state, date) => {
    state.selectedDate = date;
  },
  _setVideos: (state, { items, meta }) => {
    state.videos.items = [...state.videos.items, ...items];
    state.videos.meta = meta;
  },
  _setCurrentOrder: (state, order) => {
    state.currentOrder = order;
  },
  _setVideoLink: (state, link) => {
    state.videoLink = link;
  },
  _setOrdersOrder: (state, order) => {
    state.orders.order = order;
  },

  _cleanUpOrders: (state) => {
    state.orders = {
      items: [],
      meta: {},
    };
  },

  _cleanUpVideos: (state) => {
    state.videos = {
      items: [],
      meta: {},
    };
  },
  _cleanUpCurrentOrder: (state) => {
    state.currentStore = {};
    state.currentOrder = {};
    state.selectedDate = '';
    state.videoLink = '';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
